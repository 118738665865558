import { Controller } from "stimulus";
import "bootstrap";
// import $ from "jquery";

// require("select2/dist/css/select2");
// require("select2-bootstrap-theme/dist/select2-bootstrap");

import Select2 from "select2";

export default class extends Controller {
  connect() {
    // if (!$(".search-select-stimulus").hasClass("select2-hidden-accessible")) {
    //   $(".search-select-stimulus").select2();
    // }
    $(this.element).select2();

    $(this.element).on("select2:select", function () {
      let event = new Event("change", { bubbles: true }); // fire a native event
      this.dispatchEvent(event);
    });
  }

  disconnect() {}
}
